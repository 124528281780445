<template>
  <component :is="defineAsyncComponent(() => import('~layers/app/pages/~cms.vue'))" />
</template>

<script setup lang="ts">
import layoutQuery from '~layers/app/graphql/layout.gql'

const { public: config } = useRuntimeConfig()
const { data } = await useAsyncQuery(layoutQuery, { locale: config.strapiLocale, status: 'PUBLISHED' })

const { searchSnippet } = useSeo()
searchSnippet()

const meta = computed(() => {
  return {
    id: `strapi-${data.value?.home?.[0]?.documentId}`,
    url: '',
    page_type: 'cms-page',
    strapi_id: data.value?.home?.[0]?.documentId,
    label: 'Home',
  }
})

provide('meta', meta)
</script>
